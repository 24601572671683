// TODO: remove this file as soon as Localization with Glist is enabled on prod
import React, { memo, useEffect, useState } from 'react'
import { wfetch } from '@wf-mfe/api'
import { useCurrentCustomer } from '@wf-mfe/auth'
import { CanvasRow, WrappedCanvasDataGrid } from '@wf-mfe/canvas-data-grid'
import { useLocalizations } from '@wf-mfe/localize-react'
import { TextCell } from '@wf-mfe/spectrum-data-grid'

import { columns, USER_LOCALIZATIONS_LIMIT } from '../../constants'
import { IResult } from '../../types'
import { exGenerateCellsFromTranslations, generateTranslations } from '../../utils'

const ExLocalizationList: React.FC = () => {
  const [{ appendRowLabel }] = useLocalizations({
    appendRowLabel: {
      messageKey: 'localization.translation.list.append.row.label',
      fallback: 'Add translation',
    },
  })
  const [data, setData] = useState<Array<IResult>>([])

  const currentCustomer = useCurrentCustomer()
  const { ID: customerID } = currentCustomer

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await wfetch(`/localizer/api/messages?limit=${USER_LOCALIZATIONS_LIMIT}&page=1`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'wf-customerid': customerID,
          },
          body: JSON.stringify({ keys: [] }),
        })

        const responseData = await response.json()
        const existingMessageKeys = responseData.results

        const cellsFromTranslations = exGenerateCellsFromTranslations(columns, existingMessageKeys)
        setData(cellsFromTranslations)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [customerID])

  const handleSaveRow = async (row: CanvasRow, index: number) => {
    const [{ data: englishTranslation }] = row.cells as Array<TextCell>
    const translations = generateTranslations(columns, row)

    try {
      if (row.id.startsWith('-')) {
        const newTranslation = { id: englishTranslation, cells: row.cells } as IResult
        setData((prevData) => [...prevData, newTranslation])
      } else {
        const newTranslation = { id: englishTranslation, cells: row.cells } as IResult
        setData((prevData) => prevData.map((item, i) => (i === index ? newTranslation : item)))
      }

      const key = row.id.startsWith('-') ? '' : row.id

      await wfetch('/localizer/api/messages/bulk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'wf-customerid': customerID,
        },
        body: JSON.stringify([{ key, translations }]),
      })
    } catch (error: any) {
      throw new Error(error.errorData.title)
    }
  }
  return (
    <WrappedCanvasDataGrid
      columns={columns}
      data={data as Array<CanvasRow>}
      commitRowChanges={handleSaveRow}
      appendRowLabel={appendRowLabel}
      toolbarOptions={{
        viewActions: [],
        hasTabItems: false,
        enableSearchAction: true,
      }}
    />
  )
}

const ExMemoizedLocalizationList = memo(ExLocalizationList)

export { ExMemoizedLocalizationList as ExLocalizationList }
