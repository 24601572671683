import { IField, TFieldType } from '@wf-mfe/glist'
import { LANGUAGE_CODES, LANGUAGES } from '../types'

const validateEnglishValue = (value) => {
  if (!value.trim().length) {
    return {
      isValid: false,
      validationMessage: 'This field is required',
    }
  }

  return {
    isValid: true,
    validationMessage: '',
  }
}

const generateField = (key, displayName): IField<TFieldType> => {
  return {
    id: key,
    isPrimary: key === LANGUAGE_CODES.English,
    type: 'text',
    displayName,
    isReadonly: false,
    isViewable: false,
    isFilterable: false,
    isGroupable: false,
    isSortable: false,
    validate: key === LANGUAGE_CODES.English ? validateEnglishValue : undefined,
    config: null,
  }
}
const buildFields = (): Array<IField<TFieldType>> => {
  return Object.keys(LANGUAGES).map((key) => generateField(LANGUAGE_CODES[key], key)) // TODO: the displayName for field should be localized
}

export const fields: Array<IField<TFieldType>> = buildFields()
