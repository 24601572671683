import React, { memo, useCallback, useRef } from 'react'
import { ToastQueue } from '@react-spectrum/toast'
import { wfetch } from '@wf-mfe/api'
import { useCurrentCustomer } from '@wf-mfe/auth'
import { ICommand, ITableRow, IRow, List, useDeleteCommand, TValue } from '@wf-mfe/glist'

import { fields, USER_LOCALIZATIONS_LIMIT, TOAST_TIMEOUT } from '../../constants'
import { IItem } from '../../types'
import { generateRowsFromTranslations } from '../../utils'

const LocalizationList: React.FC = () => {
  const listRef = useRef()

  const currentCustomer = useCurrentCustomer()
  const { ID: customerID } = currentCustomer

  const getEditPayload = useCallback((editedRow) => {
    const { data: rowData } = editedRow.row

    const translations: Record<string, string> = {}

    for (const [id, translation] of Object.entries(rowData)) {
      if (editedRow.changes[id]) {
        translations[id] = editedRow.changes[id].newValue.value
      } else {
        // @ts-expect-error remove ignore when editedRow type is available
        translations[id] = translation.value
      }
    }
    const isEnglishKeyChanged = Boolean(editedRow.changes.en)

    return {
      payload: {
        key: editedRow.row.id,
        translations,
      },
      isEnglishKeyChanged,
    }
  }, [])

  const getAddPayload = useCallback((row: IRow) => {
    const { data } = row
    const translations: Record<string, TValue> = {}
    for (const [id, translation] of Object.entries(data)) {
      translations[id] = translation.value
    }
    return {
      payload: {
        key: '',
        translations,
      },
    }
  }, [])

  const fetchData = async () => {
    const response = await wfetch(`/localizer/api/messages?limit=${USER_LOCALIZATIONS_LIMIT}&page=1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'wf-customerid': customerID,
      },
      body: JSON.stringify({ keys: [] }),
    })

    const responseData = await response.json()
    const existingMessageKeys = responseData.results
    const rows = generateRowsFromTranslations(fields, existingMessageKeys)

    return {
      rows,
      totalCount: rows.length,
    }
  }

  const handleDeleteRow = useCallback(
    async (selectedRows: Array<ITableRow>) => {
      const deletedKeys = selectedRows.map((row) => row.id)
      try {
        return await wfetch('/localizer/api/messages', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'wf-customerid': customerID,
          },
          body: JSON.stringify({ keys: deletedKeys }),
        })
      } catch (err: any) {
        const error = err
        const message = error?.errorData?.title || error.message
        if (message) ToastQueue.negative(message, { timeout: TOAST_TIMEOUT })

        return error
      }
    },
    [customerID]
  )

  const upsertRecords = useCallback(
    async (records) =>
      wfetch('/localizer/api/messages/bulk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'wf-customerid': customerID,
        },
        body: JSON.stringify(records),
      }),
    [customerID]
  )

  const onRowAdded = useCallback(
    async (newRow: IRow) => {
      try {
        const { payload } = getAddPayload(newRow)
        await upsertRecords([payload])
        return generateRowsFromTranslations(fields, [payload as IItem])[0]
      } catch (err: any) {
        const error = err
        const message = error?.errorData.title || error.message
        if (message) ToastQueue.negative(message, { timeout: TOAST_TIMEOUT })

        throw error
      }
    },
    [getAddPayload, upsertRecords]
  )

  const onDataEdited = useCallback(
    async (editPayload) => {
      try {
        const editedRows = Object.keys(editPayload).map((key) => editPayload[key])
        const payloads: any = []
        const rowsToUpdate: Record<string, IRow> = {}

        editedRows.forEach((editedRow) => {
          const { payload, isEnglishKeyChanged } = getEditPayload(editedRow)
          if (isEnglishKeyChanged) {
            const { row } = editedRow
            rowsToUpdate[editedRow.changes.en.oldValue.value] = {
              ...generateRowsFromTranslations(fields, [payload as IItem])[0],
              id: row.data.en.value,
            }
          }
          payloads.push(payload)
        })

        await upsertRecords(payloads)

        // TODO: remove this when listRef types are published
        // @ts-ignore
        listRef.current?.table.updateRowsById(rowsToUpdate)
      } catch (err: any) {
        const error = err
        const message = error?.errorData?.title || error.message
        if (message) ToastQueue.negative(message, { timeout: TOAST_TIMEOUT })

        throw error
      }
    },
    [upsertRecords, getEditPayload]
  )

  const isDeleteActive = useCallback(() => {
    return true
  }, [])

  const deleteHandler = useCallback(
    async (selection: Array<ITableRow>, _: () => void) => {
      await handleDeleteRow(selection)
    },
    [handleDeleteRow]
  )

  const deleteCommand = useDeleteCommand(isDeleteActive, deleteHandler)

  const commands: Array<ICommand> = [deleteCommand]

  return (
    <List
      viewKey="localization"
      fields={fields}
      commands={commands}
      onRowsEdited={onDataEdited}
      onRowAdded={onRowAdded}
      selectionMode="multi"
      showRowIndices={false}
      ref={listRef}
      loadData={fetchData}
    />
  )
}

const MemoizedLocalizationList = memo(LocalizationList)

export { MemoizedLocalizationList as LocalizationList }
