import { GridCellKind, GridColumn } from '@wf-mfe/spectrum-data-grid'

export type CanvasColumn = {
  cellKind: GridCellKind
  isRequired?: boolean
} & GridColumn

export interface ITranslation {
  [key: string]: string
}

export interface IItem {
  customerId: string
  imsOrgId: string
  eauthTenantId: string
  productId: string
  key: string
  translations: ITranslation
}

export interface ICell {
  kind: string
  displayData: string
  data: string
  readonly: boolean
  allowOverlay: boolean
}

export interface IResult {
  id: string
  cells: Array<ICell>
}

export enum LANGUAGES {
  English = 'English',
  Chinese = 'Chinese',
  German = 'German',
  Spanish = 'Spanish',
  Korean = 'Korean',
  Japan = 'Japan',
  Italian = 'Italian',
  French = 'French',
  Portuguese = 'Portuguese (Brazil)',
  Taiwanese = 'Taiwanese Mandarin',
}

export enum LANGUAGE_CODES {
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  German = 'de',
  Italian = 'it',
  Portuguese = 'pt-BR',
  Chinese = 'zh',
  Korean = 'ko',
  Japan = 'ja',
  Taiwanese = 'zh-TW',
}
