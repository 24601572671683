import { CanvasRow } from '@wf-mfe/canvas-data-grid'
import { IField, IRow, TFieldType } from '@wf-mfe/glist'
import { TextCell } from '@wf-mfe/spectrum-data-grid'

import { CanvasColumn, IItem, IResult } from '../types'

export const generateTranslations = (columns: Array<CanvasColumn>, row: CanvasRow) =>
  columns.reduce((acc, cell, index) => {
    if (cell.id === 'messageKey') {
      return acc
    }
    const { data } = row.cells[index] as TextCell
    acc[cell.id!] = data
    return acc
  }, {})

export const generateRowsFromTranslations = (
  translationColumns: Array<IField<TFieldType>>,
  translationData: Array<IItem>
): Array<IRow> =>
  translationData.map((item) => {
    const row: IRow = { id: item.translations.en, data: {} }
    for (const translationKey of Object.keys(item.translations)) {
      row.data[translationKey] = {
        // @ts-ignore check if this is the right type
        value: item.translations[translationKey],
      }
    }
    return row
  })

// TODO: remove this function as soon as Localization with Glist is enabled on prod
export const exGenerateCellsFromTranslations = (
  translationColumns: Array<CanvasColumn>,
  translationData: Array<IItem>
): Array<IResult> =>
  translationData.map((item) => {
    const cells = translationColumns.map((column) => {
      const translation = item.translations[column.id!] || ''
      return {
        kind: column.cellKind,
        displayData: column.id === 'messageKey' ? item.key : translation,
        data: column.id === 'messageKey' ? item.key : translation,
        readonly: column.id === 'messageKey',
        allowOverlay: column.id !== 'messageKey',
      }
    })

    return { id: item.key, cells }
  })
