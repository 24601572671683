import React from 'react'
import { useIsTreatmentEnabled } from '@wf-mfe/toggles'
import { ExLocalizationList } from './ExLocalizationList'
import { LocalizationList } from './LocalizationList'

const LocalizationListWrapper: React.FC = () => {
  const localizationGlistModeEnabled = useIsTreatmentEnabled('localization-glist-mode')

  if (!localizationGlistModeEnabled) {
    return <ExLocalizationList />
  }
  return <LocalizationList />
}

export { LocalizationListWrapper as LocalizationList }
